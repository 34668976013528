import * as React from 'react';
import { Button, Input, message, Spin, Row, Col, Image } from 'antd';
import {
  EyeTwoTone,
  EyeInvisibleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import Loader from '../../common/Loader';
import { getOrganisationDetails, forgotPassword, validateOTP, updateEmployeePassword, getAvailableSSOForLogin } from '@api/login';
import { isAuthenticationHeadersPresent } from '@api/utils';
import { redirectToUrl, getURLConfigs } from '@api/utils';
import withStyles from 'react-jss';
import { getRequestHeaders } from '@components/pages/ForgotPassword/masterdata';
import styles from '@components/pages/ForgotPassword/ForgotPassword.styles';
const shipsyLogo = require('../../../assets/images/Login/shipsyLogo.svg');
const backgroundImage = require('../../../assets/images/Login/loginBg.jpg');
const shipsyLogoMain = require('../../../assets/images/ShipsyLogo.png');
import i18n from '../../../i18n';
import { isLanguageRTL } from '../../../utils/intl-utils';
import { useTranslation } from 'react-i18next';

function ForgotPasswordPage(props: any) {
  const { classes, history } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = React.useState(false);
  const [organisationDetails, setOrganisationDetails] = React.useState({} as any);
  const [urlConfigs, setURLConfigs] = React.useState({} as any);
  const [username, setUsername] = React.useState('');
  const [otp, setOtp] = React.useState('');
  const [messageContent, setMessage] = React.useState('');
  const [showOTPFields, setShowOTPFields] = React.useState(false);
  const [showNewPasswordScreens, setShowNewPasswordScreens] = React.useState(false);
  const [showPasswordResettedScreen, setShowPasswordResettedScreen] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(false);
  const [confirmPassword, setConfirmPassword] = React.useState(false);
  const [loginPageImage, setLoginPageImage] = React.useState(null);

  const showPoweredBy = !organisationDetails?.hide_poweredbyshipsy_on_public_trackpage;

  React.useEffect(() => {
    fetchOrganisationDetails();
  }, []);

  const fetchOrganisationDetails = async () => {
    setIsLoading(true);

    const urlConfigs = getURLConfigs();
    setURLConfigs(urlConfigs);

    const responseList = await Promise.all([
      getAvailableSSOForLogin({
        organisationPrettyName: urlConfigs.organisationPrettyName,
        origin: urlConfigs.origin,
      }),
      getOrganisationDetails({
        organisationPrettyName: urlConfigs.organisationPrettyName,
        origin: urlConfigs.origin,
      }),
    ]);
    const [ssoMetadataResponse, response] = responseList;

    if (response.isSuccess) {
      setOrganisationDetails(response?.data);
      if (isAuthenticationHeadersPresent()) {
        window.location.href = redirectToUrl(response?.data?.data);
      }
    } else {
      message.error(response.errorMessage);
    }

    if (ssoMetadataResponse.isSuccess) {
      const loginPageImageUrl = ssoMetadataResponse?.data?.loginPageUrl;
      setLoginPageImage(loginPageImageUrl);
    } else {
      message.error(ssoMetadataResponse.errorMessage);
    }

    setIsLoading(false);
  };

  const onChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const onChangeOTP = (e) => {
    setOtp(e.target.value);
  };

  const onChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const onClickForgotPassword = async () => {
    setMessage('');
    if (!username) {
      message.error(t('Please enter username'));
      return;
    }
    setIsForgotPasswordLoading(true);

    const reqHeaders = getRequestHeaders(urlConfigs);
    const reqBody = { username };
    const response = await forgotPassword(reqHeaders, reqBody);
    if (response.isSuccess) {
      setShowOTPFields(true);
      message.success(t('If the email is correct, you would have received the OTP. Please enter the OTP.'));
    } else {
      setMessage(response.errorMessage + ' !');
    }

    setIsForgotPasswordLoading(false);
  };

  const onClickSubmitOTP = async () => {

    if (!username) {
      message.error(t('Please enter username'));
      return;
    }

    if (!otp) {
      message.error(t('Please enter otp'));
      return;
    }

    setIsForgotPasswordLoading(true);
    setMessage('');

    const reqHeaders = getRequestHeaders(urlConfigs);
    const reqBody = { username, otp };

    const response = await validateOTP(reqHeaders, reqBody);
    if (response.isSuccess) {
      setShowOTPFields(false);
      setShowNewPasswordScreens(true);
    } else {
      message.error(response.errorMessage);
    }

    setIsForgotPasswordLoading(false);
  };

  const onClickSetNewPassword = async () => {
    if (!username) {
      message.error(t('Please enter username'));
      return;
    }

    if (!otp) {
      message.error(t('Please enter otp'));
      return;
    }

    if (!newPassword) {
      message.error(t('Please enter new password'));
      return;
    }

    if (!confirmPassword) {
      message.error(t('Please enter confirm password'));
      return;
    }

    if (newPassword !== confirmPassword) {
      message.error(t('Entered passwords does not match'));
      return;
    }

    setIsForgotPasswordLoading(true);
    setMessage('');

    const reqHeaders = getRequestHeaders(urlConfigs);
    const reqBody = { username, otp, password: newPassword };

    const response = await updateEmployeePassword(reqHeaders, reqBody);
    if (response.isSuccess) {
      setShowNewPasswordScreens(false);
      setShowPasswordResettedScreen(true);
      window.location.href = '/login';
    } else {
      message.error(response.errorMessage);
    }

    setIsForgotPasswordLoading(false);
  };

  const renderForgotPassword = () => {
    if (showOTPFields || showNewPasswordScreens || showPasswordResettedScreen) {
      return null;
    }

    return (
      <>
        <h2 className={classes.heading}>{t('Forgot Password?')}</h2>
          <div className={classes.subText}>
            <p>{t('No worries!')}</p>
            <p>{t('Please enter your username to receive a verification code')}</p>
          </div>

          <div className={classes.passwordInputHeader}>
            <div>{t('Username')}</div>
          </div>
          <div className={classes.inputBox}>
            <Input
              disabled={isForgotPasswordLoading}
              placeholder={t('Enter Username')}
              onChange={onChangeUsername}
            />
          </div>
          {messageContent &&
            <div className={classes.errorMessage}>
              {messageContent}
            </div>
          }

          <div className={classes.submitButtonContainer}>
            <Button
              className={classes.submitButton}
              type="primary"
              onClick={onClickForgotPassword}
              disabled={!username || username?.length === 0 || isForgotPasswordLoading}
              block
            >
              {isForgotPasswordLoading ?
                <Spin indicator={
                  <LoadingOutlined style={{ fontSize: 16, color: '#fff' }} spin />
                } />
                : t('Send OTP')}
            </Button>
          </div>
      </>
    );
  };

  const renderOTPScreen = () => {
    if (!showOTPFields) {
      return null;
    }

    return (
      <>
        <h2 className={classes.heading}>{t('Reset Password')}</h2>
          <div className={classes.subText}>
            <p>{t('An OTP was sent to your registered Email, please enter the verification code to proceed')}</p>
          </div>

          <div className={classes.passwordInputHeader}>
            <div>{t('OTP')}</div>
          </div>
          <div className={classes.inputBox}>
            <Input
              disabled={isForgotPasswordLoading}
              placeholder={t('Enter OTP')}
              onChange={onChangeOTP}
            />
          </div>

          {messageContent &&
            <div className={classes.errorMessage}>
              {messageContent}
            </div>
          }

          <div className={classes.submitButtonContainer}>
            <Button
              className={classes.submitButton}
              type="primary"
              onClick={onClickSubmitOTP}
              disabled={!otp || otp?.length === 0 || isForgotPasswordLoading}
              block
            >
              {isForgotPasswordLoading ?
                <Spin indicator={
                  <LoadingOutlined style={{ fontSize: 16, color: '#fff' }} spin />
                } />
                : t('Confirm')}
            </Button>
          </div>
      </>
    );
  };

  const renderNewPasswordScreen = () => {
    if (!showNewPasswordScreens) {
      return null;
    }

    return (
      <>
        <h2 className={classes.heading}>{t('Create New Password')}</h2>
          <div className={classes.subText}>
            <p>{t('Please create a new password to login')}</p>
          </div>

          <div className={classes.passwordInputHeader}>
            <div>{t('New Password')}</div>
          </div>
          <div className={classes.inputBox}>
            <Input.Password
              disabled={isForgotPasswordLoading}
              placeholder={t('Enter New Password')}
              onChange={onChangeNewPassword}
              iconRender={visible => (
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              )}
            />
          </div>
          <div className={classes.passwordInputHeader}>
            <div>{t('Confirm New Password')}</div>
          </div>
          <div className={classes.inputBox}>
            <Input.Password
              disabled={isForgotPasswordLoading}
              placeholder={t('Confirm New Password')}
              onChange={onChangeConfirmPassword}
              iconRender={visible => (
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              )}
            />
          </div>

          {messageContent &&
            <div className={classes.errorMessage}>
              {messageContent}
            </div>
          }

          <div className={classes.submitButtonContainer}>
            <Button
              className={classes.submitButton}
              type="primary"
              style={{ marginTop: '12px' }}
              onClick={onClickSetNewPassword}
              disabled={!otp || otp?.length === 0 || isForgotPasswordLoading}
              block
            >
              {isForgotPasswordLoading ?
                <Spin indicator={
                  <LoadingOutlined style={{ fontSize: 16, color: '#fff' }} spin />
                } />
                : t('Set New Password')}
            </Button>
          </div>
      </>
    );
  };

  const renderPasswordResettedScreen = () => {
    if (!showPasswordResettedScreen) {
      return null;
    }
    
    setTimeout(() => {
      setShowPasswordResettedScreen(false);
      window.location.href = '/login';
    }, 1000);

    return (
      <div className={classes.subText} style={{ marginTop: '12px' }}>
        <p>{t('Your password has been reset. Please wait while you are redirected to the dashboard')}</p>
      </div>
    );
  };

  const renderInputBox = () => {
    return (
        <div className={classes.loginContainer}>
          <div>
            <img
              className={classes.logoImage}
              src={organisationDetails.logo_url || shipsyLogoMain}
            />
          </div>
          {renderForgotPassword()}
          {renderOTPScreen()}
          {renderNewPasswordScreen()}
          {renderPasswordResettedScreen()}

          {!showPasswordResettedScreen ?
            <div className={classes.forgotPasswordBtnDiv}>
              <span style={{ fontSize: '12px' }}>{t('Back to')}</span>
              <button onClick={() => window.location.href = '/login'}>{t('Login Page')}</button>
            </div>
          : null}
        </div>
    );
  }

  const renderWithImage = () => {
    return loginPageImage ? (
      <Row className={classes.roundedAndShadowBorder}>
        <Col span={10}>{renderInputBox()}</Col>
        <Col span={14} className={classes.centeredImage} style={{ minHeight: '400px', minWidth: '400px' }} >
          <Image className={classes.imageLimit} src={loginPageImage} alt={loginPageImage} preview={false} />
        </Col>
      </Row>
    ) : (
      <Row className={classes.roundedAndShadowBorder}>{renderInputBox()}</Row>
    );
  }

  return (
    <>
      {isLoading ?
        <div className={classes.loaderContainer}>
          <Loader zIndex={100} />
        </div>
        :
        <div
          className={classes.mainContainer}
          style={{
            background: `url(${backgroundImage}) no-repeat center bottom fixed`,
            backgroundSize: 'cover',
            direction: isLanguageRTL(i18n.language) ? 'rtl' : 'ltr'
          }}
        >
          <div className={classes.topMainDiv}>
            {renderWithImage()}
          </div>
          { showPoweredBy && <div className={classes.footerDiv}>
            <span>{t('Powered By')}</span>
            <img src={shipsyLogo} alt="" />
          </div>}
        </div>
      }
    </>
  );
}

export default withStyles(styles, { injectTheme: true })(ForgotPasswordPage);
