import { WeekOffItem } from './gdm/types';
import store from '@store/appStore';

export const dispatchScreenBuckets = [
  {
    id : 'in_store_orders',
    name: 'In Store',
  },
  {
    id : 'ongoing_orders',
    name: 'Ongoing',
  },
  {
    id : 'completed_orders',
    name: 'Completed',
  },
  {
    id: 'unassigned_orders',
    name: 'Unassigned',
  },
  {
    id: 'undelivered_orders',
    name: 'Undelivered',
  },
  {
    id: 'cancelled_orders',
    name: 'Cancelled',
  },
];

export const dispatchScreenBucketsPickily = [
  {
    id: 'in_store',
    name: 'In Store',
  },
  {
    id: 'unassigned',
    name: 'Unassigned',
  },
  {
    id: 'ongoing',
    name: 'Ongoing',
  },
  {
    id: 'completed',
    name: 'Completed',
  },
  {
    id: 'rto',
    name: 'RTO',
  },
  {
    id: 'undelivered',
    name: 'Failed',
  },
  {
    id: 'cancelled',
    name: 'Cancelled',
  },
];

export const getDispatchScreenV2Buckets = () => {

  const masterData = store?.getState()?.masterData || {};
  if (masterData.create_rto_task) {
    return [
      {
        id: 'in_store',
        name: 'In Store',
      },
      {
        id: 'unassigned',
        name: 'Unassigned',
      },
      {
        id: 'ongoing',
        name: 'Ongoing',
      },
      {
        id: 'completed',
        name: 'Completed',
      },
      {
        id: 'rto',
        name: 'RTO',
      },
      {
        id: 'undelivered',
        name: 'Failed',
      },
      {
        id: 'cancelled',
        name: 'Cancelled',
      },
    ];
  }
  return [
    {
      id: 'in_store',
      name: 'In Store',
    },
    {
      id: 'unassigned',
      name: 'Unassigned',
    },
    {
      id: 'ongoing',
      name: 'Ongoing',
    },
    {
      id: 'completed',
      name: 'Completed',
    },
    {
      id: 'undelivered',
      name: 'Failed',
    },
    {
      id: 'cancelled',
      name: 'Cancelled',
    },
  ];

};

export const riderTabs = [
  {
    id: 'in_store',
    name: 'In Store',
  },
  {
    id: 'out_for_delivery',
    name: 'Out for Delivery',
  },
  {
    id: 'returning_to_store',
    name: 'Returning to Store',
  },
  {
    id:'outside_geofence',
    name:'Outside Store Geofence',
  },
];

export const effectiveRiderStatusMap = {
  in_store_assigned: 'in_store',
  on_break: 'in_store',
};

export const analyticsColors = {
  average_delivery_time : '#116390',
  average_load_time : '#BF2545',
  average_rack_time : '#DB3154',
  dot_perc_less_30_min: '#2E80AE',
  dot_perc_less_20_min: '#7A3E63',
  single_run_orders: '#9C3154',
  left_at_store_per_today: '#2E80AE',
  left_at_store_per_week: '#116390',
  avg_distance_delivered_outside_customer_geofence_today: '#2E80AE',
  avg_distance_delivered_outside_customer_geofence_week: '#116390',
};
export const analyticsUnits = {
  average_delivery_time : ' Mins',
  average_load_time : ' Mins',
  average_rack_time : ' Mins',
  dot_perc_less_30_min: '%',
  dot_perc_less_20_min: '%',
  single_run_orders: '%',
  'Avg Delivery Time' : ' Mins',
  'Avg Drive Time' : ' Mins',
  'Avg Return Time' : ' Mins',
  'Avg Load Time' : ' Mins',
  'Avg Rack Time' : ' Mins',
  'Gate to Delivered Time' : ' Mins',
  'Avg Assignment Time' : 'Mins',
  'Order To Start Bike Time' : 'Mins',
  avg_delivery_time: 'mins',
  startbike_reachedgate_time:  'mins',
  reachedgate_delivered_time:  'mins',
  delivered_reachedstore_time:  'mins',
};
export const bucketValueColors = {
  in_store_orders: '#4782BC',
  ongoing_orders : '#C5A833',
  completed_orders : '#47BC9C',
  unassigned_orders : '#EB5960',
};
export const dayPartList = [
  { name: '11 AM - 3 PM', id: [11, 15] },
  { name: '3 PM - 7 PM', id: [15, 19] },
  { name: '7 PM - 11 PM', id: [19, 23] },
  { name: '11 PM - 12 AM', id: [23, 0] },
  { name: '12 AM - 3 AM', id: [0, 3] },
];
export const timeSlotList = [
  { name: '9 AM - 10 AM', id: [9, 10] },
  { name: '10 AM - 11 AM', id: [10, 11] },
  { name: '11 AM - 12 PM', id: [11, 12] },
  { name: '12 PM - 1 PM', id: [12, 13] },
  { name: '1 PM - 2 PM', id: [13, 14] },
  { name: '2 PM - 3 PM', id: [14, 15] },
  { name: '3 PM - 4 PM', id: [15, 16] },
  { name: '4 PM - 5 PM', id: [16, 17] },
  { name: '5 PM - 6 PM', id: [17, 18] },
  { name: '6 PM - 7 PM', id: [18, 19] },
  { name: '7 PM - 8 PM', id: [19, 20] },
  { name: '8 PM - 9 PM', id: [20, 21] },
  { name: '9 PM - 10 PM', id: [21, 22] },
  { name: '10 PM - 11 PM', id: [22, 23] },
  { name: '11 PM - 12 AM', id: [23, 0] },
  { name: '12 AM - 1 AM', id: [0, 1] },
  { name: '1 AM - 2 AM', id: [1, 2] },
  { name: '2 AM - 3 AM', id: [2, 3] },
];
export const nonRegularOrderList = [{
  name: 'Regular Orders', id: 'regular', disabled: false,
}, {
  name: 'Delayed Orders', id: 'delayed',
}, {
  name: 'Bulk Orders', id: 'bulk',
}, {
  name: 'IRCTC Orders', id: 'irctc',
}];
export const ratingList = [{
  name: '1', id: '1',
}, {
  name: '2', id: '2',
}, {
  name: '3', id: '3',
}, {
  name: '4', id: '4',
}, {
  name: '5', id: '5',
},
];

export const dateFormat = 'DD-MM-YYYY';
export const timeFormat = 'HH:mm';
export const dateTimeFormat = 'DD-MM-YYYY HH:mm';
const riderStatus = {
  on_break: 'On Break',
  returning_to_store: 'Returning To Store',
  out_for_delivery: 'Out for Delivery',
  in_store: 'In Store',
  in_store_assigned: 'Assigned',
  in_transit: 'In Transit',
  outside_geofence:'Outside store geofence',
};
export function getRiderStatusPrettyName(status) {
  return status ?
    (riderStatus.hasOwnProperty(status)
      ? riderStatus[status] : status)
    : status;
}
const orderStatus = {
  outfordelivery: 'Out for Delivery',
  in_routing: 'In routing',
  in_routing_critical: 'In routing',
  in_oven: 'In Oven',
  delivered: 'Delivered',
  attempted: 'Undelivered',
  cancelled: 'Cancelled',
  startfordelivery: 'Start Bike',
  reacheddestination: 'Reached Gate',
  returntohub: 'Reached Store',
  assigned_for_delivery: 'Assigned for Delivery',
  startbike: 'Out for Delivery',
  reachedgate: 'Reached Gate',
  reachedathub: 'Received',
  unassigned: 'Unassigned',
  order_processing: 'Order Processing',
  order_details_pending: 'Order Details Pending',
  not_picked_up: 'Pickup Failed',
};
export function getOrderStatusPrettyName(status) {
  return status ?
    (orderStatus.hasOwnProperty(status)
      ? orderStatus[status] : status)
    : status;
}

export const daysOfWeekList = [
  {name: 'Sunday', id: 'sun'},
  {name: 'Monday', id: 'mon'},
  {name: 'Tuesday', id: 'tue'},
  {name: 'Wednesday', id: 'wed'},
  {name: 'Thursday', id: 'thu'},
  {name: 'Friday', id: 'fri'},
  {name: 'Saturday', id: 'sat'},
];

export const addRiderFormItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

export const addRiderTailLayout = {
  wrapperCol: { offset: 18, span: 6 },
};

export const addRiderFieldsList = [
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'worker_code',
    label: 'Worker Code',
  },
  {
    name: 'jfl_worker_role',
    label: 'Worker Role',
  },
  {
    name: 'jfl_worker_type',
    label: 'Worker Type',
  },
  {
    name: 'username',
    label: 'Username',
  },
  {
    name: 'password',
    label: 'Password',
  },
  {
    name: 'passwordCon',
    label: 'Confirm Password',
    key: 'password',
  },
  {
    name: 'phone',
    label: 'Phone',
  },
  {
    name: 'hub_code',
    label: 'Hub Code',
  },
  {
    name: 'emergency_phone',
    label: 'Emergency Phone',
  },
  {
    name: 'pan_number',
    label: 'Pan Number',
  },
  {
    name: 'is_active',
    label: 'Is Active?',
  },
  {
    name: 'vehicle_category_id',
    label: 'Vehicle Type',
  },
  {
    name: 'blood_group',
    label: 'Blood Group',
  },
  {
    name: 'date_of_joining',
    label: 'Joining Date',
  },
  {
    name: 'worker_ops_status',
    label: 'Rider ops status',
  },
  {
    name: 'termination_reason',
    label: 'Termination Reason',
  },
  {
    name: 'termination_reason_other',
    label: 'Other',
  },
  {
    name:'shifts',
    label:'Shifts',
  },
  {
    name: 'auto_create_vehicle',
    label: 'Auto Create Vehicle',
  },
  {
    name: 'vehicle_registration_number',
    label: 'Vehicle Reg. No.',
  },
  {
    name: 'rule_identifier',
    label: 'Payout Rule Identifier',
  },
  {
    name: 'gender',
    label: 'Gender'
  },
  {
    name: 'optimiser_tags',  
    label: 'Optimiser Tags'
  },
  {
    name: 'upstream_worker_code',  
    label: 'Upstream Worker Code'
  },
  {
    name: 'identification_documents',  
    label: 'Identification Documents'
  },
  {
    name: 'worker_shift_names',
    label: 'Worker Shift Names'
  },
  {
    name: 'preferred_vehicle_type',
    label: 'Preferred Vehicle Type (I)',
  },
  {
    name: 'second_preferred_vehicle_type',
    label: 'Preferred Vehicle Type (II)',
  },
  {
    name: 'vendor_code',
    label: 'Vendor Name',
  },
  {
    name: 'pincode',
    label: 'Pincode',
  },
  {
    name: 'city',
    label: 'City',
  },
  {
    name: 'state',
    label: 'State',
  },
  {
    name: 'country',
    label: 'Country',
  },
  {
    name: 'license_category_list',
    label: 'License Category List',
  },
  {
    name: 'driving_licence_validity',
    label: 'Driving License Validity',
  },
  {
    name: 'driving_licence_number',
    label: 'Driving Licence Number',
  },
];

export const addRiderLocationFields = [
  'pincode',
  'city',
  'state',
  'country',
];

export const bankDetails = [
  {
    name: 'bank_account_holder_name',
    label: 'Account holder name',
  },
  {
    name: 'bank_account_number',
    label: 'Account number',
  },
  {
    name: 'bank_name_and_branch',
    label: 'Bank Name and Branch',
  },
  {
    name: 'bank_ifsc_code',
    label: 'IFSC code',
  },
];

export const covidDetails = [
  {
    name: 'dose1',
    label: 'Dose 1',
  },
  {
    name: 'dose2',
    label: 'Dose 2',
  },
  {
    name: 'vaccination_date_dose_1',
    label: 'Date of Dose 1',
  },
  {
    name: 'vaccination_date_dose_2',
    label: 'Date of Dose 2',
  },
  {
    name: 'vaccination_certificate_link',
    label: 'Attachment',
  },
];

export const orderTypeMap = {
  EXPRESS: {
    color: '#fef8e2',
    label: 'Express',
  },
  PLANNED: {
    color: '#e2f2fd',
    label: 'Planned',
  },
};

export const getRiderWeekOffData = (workerCode) => {
  const newObj: WeekOffItem = {
    workerCode,
  };
  for (let j = 1; j < 54; j++) {
    newObj[`week${j}`] = 'Monday';
  }
  return newObj;
};

export const dispatchScreenPaginationSizes = [20, 50, 100, 200];

export const convertSecondsIntoHoursAndMinutes = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return { hours, minutes };
};

export const getFirstLetters = (str:string) => {
  const firstLetters = str
    .split(' ')
    .map(word => word[0])
    .join('');

  return firstLetters;
};

export const initializeBucketPageNumberMap = () => {
  const pageNumberMap = {};
  dispatchScreenBucketsPickily?.forEach((item) => {
      pageNumberMap[item?.id] = 1;
  });
  return pageNumberMap;
};

export const initializeBucketPageNumberMapForRiders = () => {
  const pageNumberMap = {};
  riderTabs?.forEach((item) => {
      pageNumberMap[item?.id] = 1;
  });
  return pageNumberMap;
};

export const previewStructure = [
  {
    title: 'Primary Details',
    fields: [
      {
        key: 'order_number',
        label: 'Order No.',
      },
      {
        key: 'hub_code',
        label: 'Store',
      },
      {
        key: 'order_type',
        label: 'Order Type',
      },
      {
        key: 'invoice_number',
        label: 'Invoice',
      },
      {
        key: 'upstream_creation_source',
        label: 'Order Source',
      },
      {
        key: 'tag',
        label: 'Tag',
        type: 'array',
      },
    ],
  },
  {
    title: 'Location Details',
    fields: [
      {
        key: 'pickup_location_address',
        label: 'Pickup Location',
      },
      {
        key: 'destination_location_address',
        label: 'Destination Location',
      },
      {
        key: 'time_slot',
        label: 'Time Slot',
      },
    ],
  },
  {
    title: 'Order Details',
    fields: [
      {
        key: 'hu_details',
        label: 'HU No.',
        span: 24,
      },
      {
        key: 'pickup_details_list',
        label: 'Pieces Details',
        type: 'table',
        width: '500px',
      },
    ],
  },
  {
    title: 'Payment Details',
    fields: [
      {
        key: 'payment_mode',
        label: 'Mode',
      },
      {
        key: 'final_amount',
        label: 'Amount',
      },
    ],
  },
];

export const columnsMap = {
  pickup_details_list: [
    {
      key: 'menu_code',
      pretty_name: 'Name',
    },
    {
      key: 'size',
      pretty_name: 'Weight',
    },
    {
      key: 'quantity',
      pretty_name: 'Quantity',
    },
    {
      key: 'unit_price',
      pretty_name: 'Unit Price',
    },
    {
      key: 'order_line_total',
      pretty_name: 'Price',
    },
  ],
};

export const orderCreationFormSteps = [
  {
      key: 'primaryDetails',
      title: 'Primary Details',
  },
  {
      key: 'locationDetails',
      title: 'Location Details',
  },
  {
      key: 'orderDetails',
      title: 'Order Details',
  },
];

export const generatePieceDetailObject = () => {
  return {
      index: 0,
      menu_code: '',
      menu_description: '',
      size: '',
      quantity: 0,
      order_line_total: 0,
      unit_price: 0,
  };
};

export const genderList = [
  {
    key: 'Male',
    value: 'male'
  },
  {
    key: 'Female',
    value: 'female'
  },
  {
    key: 'Not Specified',
    value: 'not_specified'
  }
];